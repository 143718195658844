<template>
  <main class="not-found">
    <h1>404</h1>
  </main>
</template>

<script>
export default {
  name: '404',
}
</script>

<style lang="scss">
@import "../assets/scss/_variables";
@import "../assets/scss/_mixins";


</style>
